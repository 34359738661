import dayjs from 'dayjs'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { RxDotsHorizontal as TypingDots } from 'react-icons/rx'
import styled from 'styled-components'

import { partition } from '@/util/array.ts'
import {
  ConversationEvent,
  InternalConversationEvent,
} from '~common/serverToClientParser.ts'

import { ChatComponent } from './ChatComponent.tsx'

interface ChatAreaProps {
  assignedToId?: string
  createdById?: string
  events?: ConversationEvent[] | InternalConversationEvent[]
  hideAttachments?: boolean,
  typingUserName?: string
}

const ChatContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const TypingContainer = styled.div`
  color: ${(props) => props.theme.colors.common.selected};
`

const DotsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.colors.common.accentLight};
  border-radius: 0px 8px 8px 8px;
  padding: 8px;
  width: 68px;
  height: 46px;
`
const LastElement = styled.div``

type UnionEvent = ConversationEvent | InternalConversationEvent

function groupEvents(events: UnionEvent[]): UnionEvent[][] {
  return partition(events, (e) => {
    const timestamp = dayjs(e.timestamp).format('YYYY-MM-DDTHH:mm')
    return e.type === 'ChatMessageAdded' ||
      e.type === 'InternalChatMessageAdded'
      ? `chat${e.userId}${timestamp}`
      : `other${timestamp}`
  })
}

export const PlainChatContent = ({
  chatContainerRef,
  events = [],
  typingUserName,
  ...rest
}: ChatAreaProps & {
  chatContainerRef?: React.Ref<HTMLDivElement>
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'chat' })
  const groupedEvents = groupEvents(events)

  return (
    <ChatContentContainer>
      {groupedEvents.flatMap((group) =>
        group.map((e, i) => (
          <ChatComponent
            key={e.id}
            event={e}
            isFirst={i === 0}
            isLast={i === group.length - 1}
            {...rest}
          />
        )),
      )}
      {typingUserName ? (
        <>
          <TypingContainer>
            {t('userTyping', { name: typingUserName })}
          </TypingContainer>
          <DotsContainer>
            <TypingDots />
          </DotsContainer>
        </>
      ) : null}
      <LastElement ref={chatContainerRef} />
    </ChatContentContainer>
  )
}

export default function ChatContent({ events = [], ...rest }: ChatAreaProps) {
  const chatContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (chatContainerRef) {
      chatContainerRef.current?.scrollIntoView()
    }
  }, [chatContainerRef, events])

  return (
    <PlainChatContent
      chatContainerRef={chatContainerRef}
      events={events}
      {...rest}
    />
  )
}
